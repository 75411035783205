import ExternalLink from "@/components/shared/ExternalLink";
import Link from "@/components/shared/Link";
import { useLinkPasting } from "@/hooks/useLinkPasting";
import { useShowScrapeResultsSidebar } from "@/lib/graphql/state";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { useTailwindBreakpoint } from "@phiaco/phia-ui";
import { customCN } from "@phiaco/phia-ui/dist/util";
import Logo from "./Logo";

// A wrapper to make logging propertieseasier
const IntLink: React.FC<{
  href: string;
  text: string;
}> = ({ href, text }) => (
  <Link
    eventName={MixpanelEventName.FOOTER_LINK_CLICKED}
    eventProperties={{ slug: href }}
    href={href}
    className="label-sm">
    {text}
  </Link>
);

// A wrapper to make logging properties easier
const ExtLink: React.FC<{
  href: string;
  text: string;
}> = ({ href, text }) => (
  <ExternalLink
    eventName={MixpanelEventName.FOOTER_LINK_CLICKED}
    eventProperties={{ slug: href }}
    href={href}
    className="label-sm"
    target="_blank">
    {text}
  </ExternalLink>
);

const Footer: React.FC = () => {
  const { showLinkPastingView } = useLinkPasting();
  const isSidebarVisible = useShowScrapeResultsSidebar();
  const { screenSize } = useTailwindBreakpoint();
  const showMarginLeft = screenSize === "xl" || screenSize === "2xl";

  return (
    <footer
      className={customCN(
        "mx-auto flex h-[374px] max-w-[1400px] flex-col justify-between px-4 py-[60px] text-cn-primary sm:px-8",
        showLinkPastingView &&
          isSidebarVisible &&
          showMarginLeft &&
          "ml-[400px]"
      )}>
      <div className="mb-4 flex flex-1 flex-col-reverse justify-between sm:flex-row sm:items-start">
        <Logo />
        <nav className="grid grid-cols-2 gap-x-10 gap-y-4">
          <IntLink href={routes.ABOUT_PHIA} text="About Phia" />
          <ExtLink href="https://www.instagram.com/phiaco/" text="Instagram" />
          <IntLink href={routes.CONTACT_AND_SUPPORT} text="Contact & Support" />
          <ExtLink href="https://twitter.com/Phia__co" text="Twitter" />
          <IntLink
            href={routes.TERMS_AND_CONDITIONS}
            text="Terms & Conditions"
          />
          <ExtLink
            href="https://www.tiktok.com/@phia?_t=8s6emjvNwbK&_r=1"
            text="TikTok"
          />
          <IntLink href={routes.PRIVACY_POLICY} text="Privacy Policy" />
          <ExtLink
            href="https://www.linkedin.com/company/phia-co/"
            text="LinkedIn"
          />
          <ExtLink href="https://sovrn.co/1k22gxc" text="Macy's" />
          <IntLink
            href={routes.EXTENSION_SITE_SUPPORT}
            text="Suggest site for extension"
          />
        </nav>
      </div>
      <p className="para-sm">
        © {new Date().getFullYear()} • All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
